/* Bourbon */
/*<editor-fold desc="imports">*/
/* Bourbon */
.clearfix,
.group {
  zoom: 1; }
  .clearfix:before, .clearfix:after,
  .group:before,
  .group:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .clearfix:after,
  .group:after {
    clear: both; }

.buffer-xs-0 {
  margin-top: 0; }

.buffer-xs-10 {
  margin-top: 10px; }

.buffer-xs-20 {
  margin-top: 20px; }

@media (min-width: 768px) {
  .buffer-sm-0 {
    margin-top: 0; }
  .buffer-sm-10 {
    margin-top: 10px; } }

@media (min-width: 992px) {
  .buffer-md-0 {
    margin-top: 0; }
  .buffer-md-10 {
    margin-top: 10px; } }

.icheckbox_square-blue,
.iradio_square-blue {
  background-image: url("../../img/iCheck/square/blue.png");
  /* HiDPI support */ }
  @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_square-blue,
    .iradio_square-blue {
      background-image: url("../../img/iCheck/square/blue@2x.png"); } }

.bannerMfp .mfp-iframe {
  background: none;
  box-shadow: none; }

.mfp-bg {
  z-index: 999998; }

.mfp-wrap {
  z-index: 999999; }

.uno-mfp-popup {
  max-width: 600px;
  margin: 10px auto;
  color: #000;
  font-family: helvetica, arial, sans-serif;
  position: relative; }
  .uno-mfp-popup.white {
    background-color: white; }
    .uno-mfp-popup.white .mfp-close {
      color: black;
      font-size: 22px; }
    .uno-mfp-popup.white > .box {
      border-radius: 0; }
  .uno-mfp-popup > .popup-container {
    padding: 20px; }
    .uno-mfp-popup > .popup-container > h4 {
      margin: 0; }

.mfp-iframe-holder .mfp-content {
  max-width: 1200px; }

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out; }

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out; }

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

.mfp-close-btn-in .mfp-close {
  color: #FFF; }

.select2-dropdown, .select2-container--bootstrap .select2-search--dropdown .select2-search__field, .select2-container--bootstrap .select2-selection {
  border-radius: 0; }

.select2-container--bootstrap .select2-results__option[role="group"] .select2-results__option {
  padding-left: 40px; }

.slick-loading .slick-list {
  background-image: url("../../img/slick/ajax-loader.gif"); }

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url("../../fonts/slick/slick.eot");
  src: url("../../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../../fonts/slick/slick.woff") format("woff"), url("../../fonts/slick/slick.ttf") format("truetype"), url("../../fonts/slick/slick.svg#slick") format("svg"); }

.slick-arrow:before {
  transition: opacity 0.5s; }

/* TinyMCE Fullscreen Z-Index Override */
div.mce-fullscreen {
  z-index: 9999; }

label.mce-label {
  max-width: none; }

/* TINYMCE CUSTOMISATION */
.mce-window {
  max-width: 90% !important; }

.mce-panel {
  max-width: 100% !important; }

.mce-tabs {
  max-width: 100% !important; }

.mce-container-body {
  max-width: 100% !important; }

.mce-container {
  max-width: 100% !important; }

.mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.svg"); }

.no-svg .mejs-overlay-button {
  background-image: url("../../assets/mediaelement/bigplay.png"); }

.mejs-overlay-loading {
  background-image: url("../../assets/mediaelement/background.png"); }
  .mejs-overlay-loading span {
    background-image: url("../../assets/mediaelement/loading.gif"); }

.mejs-container .mejs-controls,
.mejs-controls .mejs-volume-button .mejs-volume-slider,
.mejs-controls .mejs-captions-button .mejs-captions-selector,
.mejs-captions-text,
.mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
.mejs-postroll-layer,
.mejs-postroll-close,
.mejs-controls .mejs-speed-button .mejs-speed-selector {
  background-image: url("../../assets/mediaelement/background.png"); }

.mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.svg"); }

.no-svg .mejs-controls .mejs-button button {
  background-image: url("../../assets/mediaelement/controls.png"); }

.mejs-controls .mejs-button.mejs-skip-back-button {
  background-image: url("../../assets/mediaelement/skipback.png"); }

.jstree-default .jstree-icon,
.jstree-default .jstree-file,
.jstree-default .jstree-folder {
  background-image: url("../../img/jstree/32px.png"); }

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background-image: url("../../img/jstree/throbber.gif"); }

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er,
#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er,
.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon,
#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url("../../img/jstree/32px.png"); }

.jstree-default-small .jstree-icon,
.jstree-default-small .jstree-file,
.jstree-default-small .jstree-folder {
  background-image: url("../../img/jstree/32px.png"); }

.jstree-default-large .jstree-icon,
.jstree-default-large .jstree-file,
.jstree-default-large .jstree-folder {
  background-image: url("../../img/jstree/32px.png"); }

#jstree-dnd.jstree-dnd-responsive > .jstree-ok,
#jstree-dnd.jstree-dnd-responsive > .jstree-er {
  background-image: url("../../img/jstree/40px.png"); }

.jstree-default-responsive .jstree-node,
.jstree-default-responsive .jstree-icon,
.jstree-default-responsive .jstree-node > .jstree-ocl,
.jstree-default-responsive .jstree-themeicon,
.jstree-default-responsive .jstree-checkbox,
.jstree-default-responsive .jstree-file,
.jstree-default-responsive .jstree-folder {
  background-image: url("../../img/jstree/40px.png"); }

/* No bold */
.no-bold {
  font-weight: normal; }

/* IE Image Fix */
img {
  -ms-interpolation-mode: bicubic; }

/* Easy background image */
.bg-img {
  background: no-repeat center; }
  .bg-img.bg-cover {
    background-size: cover; }
  .bg-img.bg-contain {
    background-size: contain; }

/* Button Block Text Overflow */
.btn-block {
  text-overflow: ellipsis;
  overflow: hidden; }

/* Hide CSRF Token */
#_token {
  display: none; }

/* Bootstrap: Remove gutter */
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

/* Remove Transition */
.no-transition {
  transition: none !important; }

a.block {
  display: block; }

.btn:focus {
  outline: none; }

textarea.autosize {
  resize: none; }

/*<editor-fold desc="fonts">*/
@font-face {
  font-family: "opensans-regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/opensans-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/opensans-regular.woff") format("woff"), url("../../fonts/webfonts/opensans-regular.ttf") format("truetype"), url("../../fonts/webfonts/opensans-regular.svg#opensans-regular") format("svg"); }

@font-face {
  font-family: "opensans-bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/webfonts/opensans-bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/webfonts/opensans-bold.woff") format("woff"), url("../../fonts/webfonts/opensans-bold.ttf") format("truetype"), url("../../fonts/webfonts/opensans-bold.svg#opensans-bold") format("svg"); }

#breadcrumb .crumbs, #breadcrumb .crumb + .crumb:before, .select2-container, #newsletter .input-cont input, #search input {
  font-family: "opensans-regular";
  font-style: italic;
  font-size: 12px; }

.header-top .user-pref#language .chosen, footer #footer-content h4, .footer-top .quick-nav a, .footer-bottom {
  font-family: "arial";
  font-size: 11px; }

#breadcrumb h2, .news-mini .slides h3, .course-content .course-banner h4, #newsletter h3, .parent-label .parent-name {
  font-family: "opensans-bold";
  font-size: 18px; }

p, #menu nav ul li:not(last-child) a, #content .page-content p, .news-mini .slides p, .course-mini .course-list .slide p, .student-testimonial .student-slider .desc p {
  font-family: "arial";
  font-size: 13px; }

.course-content .course-details .detail .info, .home-map .home-map-details h4, .home-map .home-map-details .contact-label, .home-map .home-map-details p, .side-menu ul li ul li a {
  font-family: "opensans-regular";
  font-size: 13px; }

.news-mini .slides a.more, .course-content .course-page-cont .nav li a, .back-top, .side-menu ul li a, .side-guide ul li {
  font-family: "arial";
  font-weight: bold;
  font-size: 12px; }

.btn, .header-top .user-pref, .header-contact a, .course-content .course-page-cont .tab-mobile, .subpage .nav li a, .subpage .tab-content .tab-mobile {
  font-family: "opensans-bold";
  font-size: 12px; }

#home-static .why-sheffield-list li h3, .course-content .course-details .detail .label, .subpage .tab-content .tab-pane h3 {
  font-family: "arial";
  font-size: 18px; }

#banner .slides .slide .desc .link, #content .content-top .or, .student-testimonial .featured p {
  font-family: "arial";
  font-size: 13px; }

.student-testimonial .featured h3 {
  font-family: "opensans-bold";
  font-size: 25px; }

#content .content-top .title-txt {
  font-family: "opensans-bold";
  font-size: 22px; }

.course-mini .course-list .slide h4, .student-testimonial .student-slider .desc h3 {
  font-family: "opensans-bold";
  font-size: 14px; }

.course-mini .course-mini-title h2 {
  font-family: "arial";
  font-size: 25px; }

#banner .slides .slide .desc .title {
  font-family: "opensans-bold";
  font-size: 22px; }

/*</editor-fold>*/
body {
  font-family: "opensans-regular", sans-serif;
  font-size: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2; }

blockquote {
  border-left: 2px solid #193d7e;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 1.4em;
  font-style: italic;
  margin: 0 0 20px;
  padding-left: 25px; }

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
  line-height: 1;
  font-weight: bold; }

h1 {
  font-size: 42px;
  font-style: normal;
  margin: 0 0 25px; }

h2 {
  font-size: 30px;
  font-style: normal;
  margin: 0 0 25px; }

h3 {
  font-size: 24px;
  font-style: normal;
  margin: 0 0 25px; }

h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0 0 25px; }

h5 {
  font-size: 16px;
  font-style: normal;
  margin: 0 0 25px; }

p {
  margin: 0 0 20px;
  line-height: 1.4; }

pre {
  background: #222;
  color: #fff;
  display: block;
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 20px;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word; }

code {
  border-radius: 5px;
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
  font-size: 13px;
  line-height: 20px;
  padding: 2px 10px;
  word-break: break-all;
  word-wrap: break-word; }

a {
  color: #193d7e;
  display: inline-block; }
  a:hover, a:focus {
    text-decoration: none; }

/*

// Responsive styles if needed
// @include responsive($big-break) {
// }
@include responsive($small-break) {
  body {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4,
  h5,
  p,
  ul,
  ol {
    font-size: 14px;
  }
}
*/
html, body, #wrapper {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%; }

#wrapper {
  display: table;
  width: 100%;
  table-layout: fixed; }

main {
  display: table-row; }

header {
  display: table-row;
  height: 1px; }

footer {
  display: table-row;
  height: 1px; }

.mm-page {
  height: 100%; }

/* columns of same height styles */
.row-full-height {
  height: 100%; }

.col-full-height {
  height: 100%;
  vertical-align: middle; }

.row-same-height, .row-md-same-height, .row-sm-same-height, .row-xs-same-height {
  display: table;
  table-layout: fixed;
  width: 100%;
  vertical-align: middle; }

/*revert back to normal in md*/
@media (max-width: 991px) {
  .row-md-same-height {
    display: block; } }

@media (max-width: 767px) {
  .row-sm-same-height {
    display: block; } }

.col-xs-height {
  display: table-cell;
  float: none !important; }

/*alignments*/
.col-top {
  vertical-align: top; }

.col-middle {
  vertical-align: middle; }

.col-bottom {
  vertical-align: bottom; }

@media (min-width: 768px) {
  .col-sm-height {
    display: table-cell;
    float: none !important; } }

@media (min-width: 992px) {
  .col-md-height {
    display: table-cell;
    float: none !important; } }

@media (min-width: 1000px) {
  .col-lg-height {
    display: table-cell;
    float: none !important; } }

img,
video,
audio,
iframe,
object {
  max-width: 100%; }

.btn {
  background-color: #b36d00;
  text-transform: uppercase;
  transition: all 0.5s ease;
  height: auto;
  line-height: 32px;
  border-radius: 1px;
  border: 1px solid #b36d00;
  color: white;
  display: inline-block;
  outline: none;
  padding: 0 25px;
  text-align: center;
  text-decoration: none; }
  .btn:hover, .btn:focus {
    background: #193d7e;
    border-color: #193d7e;
    color: #fff;
    text-decoration: none; }
  .btn:active {
    background: #112853;
    border-color: #112853; }

.btn-primary {
  background-color: #193d7e;
  border-color: #193d7e;
  color: #fff; }
  .btn-primary:hover, .btn-primary:focus {
    background: #153369;
    border-color: #153369;
    color: #fff; }
  .btn-primary:active {
    background: #112853;
    border-color: #112853;
    color: #fff; }

.btn-o {
  background-color: transparent;
  color: #b36d00; }
  .btn-o:hover {
    background-color: #b36d00;
    border: 1px solid #b36d00;
    color: white; }

.btn-o-primary {
  background-color: transparent;
  color: #193d7e;
  border: 1px solid #193d7e; }
  .btn-o-primary:hover {
    background-color: #193d7e;
    border: 1px solid #193d7e;
    color: white; }

.btn-lg {
  font-size: 1.4em;
  line-height: 48px;
  padding: 0 60px; }

.btn-sm {
  font-size: .85em;
  line-height: 22.4px;
  padding: 0 10px; }

.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: #193d7e;
  text-shadow: none;
  text-transform: none; }
  .btn-link:hover, .btn-link:focus {
    background: transparent;
    color: #193d7e;
    text-decoration: underline; }

@media screen and (max-width: 480px) {
  .btn {
    display: block;
    margin-bottom: 10px;
    width: 100%; } }

table {
  width: 100%; }
  table th,
  table td {
    padding: 5px;
    text-align: left; }
  table th {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc; }

label {
  display: block;
  margin-bottom: 5px; }

input[type=text],
input[type=email],
input[type=phone],
input[type=password],
input[type=number],
input[type=search],
textarea {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 1px;
  border: 1px solid #ccc;
  height: 32px;
  outline: none;
  padding: 5px 10px;
  /*width: 300px;*/
  max-width: 100%; }
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=phone]:focus,
  input[type=password]:focus,
  input[type=number]:focus,
  input[type=search]:focus,
  textarea:focus {
    border-color: #193d7e; }
  input[type=text].full-width,
  input[type=email].full-width,
  input[type=phone].full-width,
  input[type=password].full-width,
  input[type=number].full-width,
  input[type=search].full-width,
  textarea.full-width {
    width: 100%; }

textarea {
  height: 96px; }

form {
  margin-bottom: 40px; }
  form .field {
    margin-bottom: 20px; }
  form .hint {
    color: #aaa;
    margin-top: 5px; }
  form .is-required {
    font-style: italic; }
  form .has-error .error-description {
    color: #ff4136;
    margin-top: 5px; }
  form .has-error input[type=text],
  form .has-error input[type=email],
  form .has-error input[type=phone],
  form .has-error input[type=password],
  form .has-error input[type=number],
  form .has-error input[type=search],
  form .has-error textarea {
    border-color: #ff4136; }

.alert {
  zoom: 1;
  border-left: 3px solid;
  margin-bottom: 40px;
  padding: 10px; }
  .alert:before, .alert:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden; }
  .alert:after {
    clear: both; }
  .alert.alert-danger {
    background: #ffeae9;
    border-color: #ff4136;
    color: #e90d00; }
  .alert.alert-success {
    background: #e6f9e8;
    border-color: #2ecc40;
    color: #25a233; }
  .alert.alert-warning {
    background: #fff2e7;
    border-color: #ff851b;
    color: #e76b00; }
  .alert.alert-info {
    background: #cfe9ff;
    border-color: #0074d9;
    color: #0059a6; }
  .alert.alert-empty {
    background: #f4f4f4;
    border: 1px dashed #ddd;
    text-align: center; }
  .alert p:last-child {
    margin-bottom: 0; }

#menu {
  padding: 0; }
  #menu nav {
    margin: 0 0 10px; }
    #menu nav > ul > li:last-child ul {
      left: auto;
      right: 0; }
      #menu nav > ul > li:last-child ul ul {
        right: 100%; }
    #menu nav ul {
      text-align: right;
      list-style: none;
      position: relative;
      display: block;
      padding: 0;
      margin: 0; }
      body[dir="rtl"] #menu nav ul {
        text-align: left; }
      #menu nav ul li {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 0; }
        #menu nav ul li + li {
          margin-left: 30px; }
          body[dir="rtl"] #menu nav ul li + li {
            margin: 0 30px 0 0; }
        #menu nav ul li:not(last-child) a {
          display: block;
          text-decoration: none;
          font-size: 11px;
          font-weight: bold;
          line-height: 1;
          padding: 5px 0;
          text-transform: uppercase;
          color: #193d7e; }
        #menu nav ul li:hover > ul {
          display: block; }
        #menu nav ul li ul {
          color: white;
          display: none;
          position: absolute;
          top: 100%;
          left: -10px;
          text-align: left;
          padding: 0;
          z-index: 99999;
          padding-top: 10px;
          margin: 0; }
          #menu nav ul li ul li {
            margin: 0;
            float: none;
            width: 200px; }
            #menu nav ul li ul li a {
              margin: 0;
              border-radius: 0;
              background-color: white;
              color: #193d7e;
              padding: 10px;
              word-wrap: normal;
              display: inline-block;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              display: block; }
            #menu nav ul li ul li:hover > a {
              background-color: #193d7e;
              color: white; }
            #menu nav ul li ul li ul {
              top: 0;
              left: 100%;
              right: auto;
              padding: 0; }

* {
  box-sizing: border-box !important; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.clear {
  clear: both; }

.right {
  float: right; }

.left {
  float: left; }

.list-unstyled {
  list-style: none;
  margin-left: 0;
  padding-left: 0; }

.valign {
  vertical-align: middle; }

hr {
  border: 0;
  border-bottom: 1px solid #ddd;
  margin: 40px 0; }

.subtle {
  color: #aaa; }

.relative {
  position: relative; }

.padding-0 {
  padding: 0; }

.padding-5 {
  padding: 5px; }

.padding-10 {
  padding: 10px; }

.padding-15 {
  padding: 15px; }

.padding-20 {
  padding: 20px; }

.padding-30 {
  padding: 30px; }

.padding-side-5 {
  padding-left: 5px;
  padding-right: 5px; }

.padding-side-10 {
  padding-left: 10px;
  padding-right: 10px; }

.padding-side-15 {
  padding-left: 15px;
  padding-right: 15px; }

.padding-side-20 {
  padding-left: 20px;
  padding-right: 20px; }

.padding-side-30 {
  padding-left: 30px;
  padding-right: 30px; }

/*</editor-fold>*/
/*overwrite bootstrap*/
.container {
  padding-left: 60px;
  padding-right: 60px; }
  @media (max-width: 1200px) {
    .container {
      padding-left: 50px;
      padding-right: 50px; } }
  @media (max-width: 991px) {
    .container {
      padding-left: 15px;
      padding-right: 15px; } }

/*<editor-fold desc="misc">*/
.relative {
  position: relative; }

.row-mini {
  margin-right: -7.5px;
  margin-left: -7.5px; }

.col-mini {
  padding-right: 7.5px;
  padding-left: 7.5px; }

.row-sm {
  margin-right: -15px;
  margin-left: -15px; }
  @media (max-width: 767px) {
    .row-sm {
      margin-right: 0;
      margin-left: 0; } }

.row-md {
  margin-right: -15px;
  margin-left: -15px; }
  @media (max-width: 991px) {
    .row-md {
      margin-right: 0;
      margin-left: 0; } }

.text-sm-right {
  text-align: right; }
  @media (max-width: 767px) {
    .text-sm-right {
      text-align: initial; } }

.text-md-right {
  text-align: right; }
  @media (max-width: 991px) {
    .text-md-right {
      text-align: initial; } }

.inline-block {
  display: inline-block; }

.no-padding {
  padding: 0; }

.left-0-padding {
  padding-left: 0; }

.right-0-padding {
  padding-right: 0; }

.top-0-padding {
  padding-top: 0; }

.bottom-0-padding {
  padding-bottom: 0; }

.dark-blue {
  color: #004161; }

.inline-block {
  display: inline-block; }

.block {
  display: block; }

.inline {
  display: inline; }

/*</editor-fold>*/
form {
  margin: 0; }

.title-lg-inline {
  display: inline-block; }
  @media (max-width: 991px) {
    .title-lg-inline {
      display: block; } }

.title-md-inline {
  display: inline-block; }
  @media (max-width: 767px) {
    .title-md-inline {
      display: block; } }

.center-of-unknown:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.center-of-unknown > * {
  display: inline-block;
  vertical-align: middle;
  max-width: 98%; }

#header-content {
  z-index: 2;
  position: relative; }

.header-top {
  background-color: #193d7e;
  padding: 0;
  color: #ffffff;
  text-align: right;
  /*end of USER-PREF*/ }
  .header-top .border {
    margin: 0 5px; }
  .header-top .user-pref {
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 1px;
    /*end of language*/ }
    .header-top .user-pref#language {
      position: relative;
      z-index: 10; }
      .header-top .user-pref#language:hover {
        color: #f4bd00; }
        .header-top .user-pref#language:hover .language-cont {
          display: block; }
        .header-top .user-pref#language:hover .chosen:after {
          content: '\f106'; }
          body[dir="rtl"] .header-top .user-pref#language:hover .chosen:after {
            display: none; }
        body[dir="rtl"] .header-top .user-pref#language:hover .chosen:before {
          content: '\f106'; }
      .header-top .user-pref#language .chosen {
        padding: 10px 0 10px 20px;
        display: inline-block;
        vertical-align: middle; }
        body[dir="rtl"] .header-top .user-pref#language .chosen {
          padding: 10px 20px 10px 0; }
        .header-top .user-pref#language .chosen:after {
          content: '\f107';
          font-family: FontAwesome;
          margin-left: 5px; }
          body[dir="rtl"] .header-top .user-pref#language .chosen:after {
            display: none; }
        body[dir="rtl"] .header-top .user-pref#language .chosen:before {
          content: '\f107';
          font-family: FontAwesome;
          margin-right: 5px; }
      .header-top .user-pref#language .language-cont {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        margin: 0;
        padding: 0;
        background-color: #193d7e;
        /*end of li*/ }
        .header-top .user-pref#language .language-cont li {
          display: block;
          /*end of a*/ }
          .header-top .user-pref#language .language-cont li * {
            display: inline-block;
            vertical-align: middle; }
          .header-top .user-pref#language .language-cont li a {
            text-transform: uppercase;
            color: white;
            font-size: 10px;
            text-decoration: none;
            line-height: 1;
            padding: 10px; }
            .header-top .user-pref#language .language-cont li a:hover {
              color: #f4bd00; }
          .header-top .user-pref#language .language-cont li .flag {
            vertical-align: middle; }
    .header-top .user-pref#login {
      text-transform: uppercase; }
      .header-top .user-pref#login a {
        color: white; }

.header-contact {
  text-align: right; }
  body[dir="rtl"] .header-contact {
    text-align: left; }
  @media (max-width: 767px) {
    .header-contact {
      text-align: center; } }
  .header-contact a {
    padding: 10px 5px 10px 15px;
    position: relative;
    color: #193d7e; }
    .header-contact a + a {
      margin-left: 10px; }
      body[dir="rtl"] .header-contact a + a {
        margin: 0 10px 0 0; }
    .header-contact a.phone:before {
      content: '\f098';
      font-family: FontAwesome;
      font-size: 12px;
      position: absolute;
      left: 0; }
    .header-contact a.email:before {
      content: '\f099';
      font-family: FontAwesome;
      font-size: 12px;
      position: absolute;
      left: 0; }

.header-bottom #logo {
  margin: 30px 0;
  padding: 15px 0; }
  @media (max-width: 767px) {
    .header-bottom #logo {
      text-align: center;
      margin: 0; } }
  .header-bottom #logo * {
    display: inline-block;
    vertical-align: middle; }
  .header-bottom #logo img {
    margin-right: 10px; }
    body[dir="rtl"] .header-bottom #logo img {
      margin: 0 0 0 10px; }
  .header-bottom #logo h2 {
    margin: 0; }
    .header-bottom #logo h2 .upper {
      text-transform: uppercase; }

.header-bottom .header-right {
  padding-right: 0; }
  @media (max-width: 767px) {
    .header-bottom .header-right {
      padding: 0 15px; } }

.mid-header {
  text-align: right; }
  body[dir="rtl"] .mid-header {
    text-align: left; }
  @media (max-width: 767px) {
    .mid-header {
      text-align: center; } }

#header-btns {
  padding: 0;
  margin-bottom: 10px; }
  #header-btns .btn {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px; }
    #header-btns .btn + .btn {
      margin-left: 10px; }
      body[dir="rtl"] #header-btns .btn + .btn {
        margin: 0 10px 0 0; }
      @media (max-width: 480px) {
        #header-btns .btn + .btn {
          margin-left: 0; } }

#header-title h2 {
  text-transform: uppercase;
  line-height: 1;
  margin: 0; }

#menuBtn {
  margin-right: 15px;
  font-size: 38px; }

#banner {
  position: relative;
  transition: all 0.5s ease-in-out;
  /*end of slides*/ }
  #banner .slides {
    margin: 0; }
    #banner .slides .slide {
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 480px; }
      @media (max-width: 767px) {
        #banner .slides .slide {
          height: 320px; } }
      #banner .slides .slide:before {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 60%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        background-image: linear-gradient(to right,rgba(0, 0, 0, 0.6) 0%, transparent 100%);
        background-color: transparent; }
        body[dir="rtl"] #banner .slides .slide:before {
          left: auto;
          right: 0;
          background-color: rgba(0, 0, 0, 0.6);
          background-image: linear-gradient(to left,rgba(0, 0, 0, 0.6) 0%, transparent 100%);
          background-color: transparent; }
      #banner .slides .slide .container {
        height: 100%; }
      #banner .slides .slide[href="#"] {
        cursor: default; }
      #banner .slides .slide .desc {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        max-width: 40%;
        padding-bottom: 30px;
        z-index: 2;
        min-width: 200px; }
        @media (max-width: 767px) {
          #banner .slides .slide .desc {
            max-width: 320px; } }
        #banner .slides .slide .desc .title {
          color: white;
          text-transform: uppercase;
          text-align: left;
          line-height: 1;
          margin-bottom: 15px; }
        #banner .slides .slide .desc p {
          color: white;
          font-size: 16px; }
        #banner .slides .slide .desc .link {
          color: #f4bd00;
          position: absolute;
          bottom: 0;
          left: 0; }
          body[dir="rtl"] #banner .slides .slide .desc .link {
            left: auto;
            right: 0; }
          #banner .slides .slide .desc .link:after {
            content: '>';
            margin-left: 5px; }
            body[dir="rtl"] #banner .slides .slide .desc .link:after {
              content: none; }
          body[dir="rtl"] #banner .slides .slide .desc .link:before {
            content: '<';
            margin-left: 5px; }

#home div.title {
  position: relative;
  text-align: center;
  margin-bottom: 30px; }
  #home div.title:before {
    content: '';
    border: 1px solid rgba(125, 125, 125, 0.4);
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin: 0; }
  #home div.title h2 {
    text-transform: uppercase;
    background-color: white;
    padding: 0 30px !important;
    position: relative;
    z-index: 1;
    display: inline-block;
    margin: 0 !important;
    color: #2559b4;
    letter-spacing: 1px; }

#home .page-content {
  padding-top: 50px; }

#home-static .why-sheffield-list {
  padding: 0;
  list-style: none;
  text-align: center;
  margin-bottom: -30px; }
  #home-static .why-sheffield-list li {
    margin-bottom: 30px; }
    #home-static .why-sheffield-list li img {
      margin-bottom: 10px; }
    #home-static .why-sheffield-list li h3 {
      color: #b36d00;
      margin-bottom: 10px; }
    #home-static .why-sheffield-list li a {
      display: inline-block; }
      #home-static .why-sheffield-list li a.btn {
        margin: 10px 0; }
    #home-static .why-sheffield-list li p {
      text-align: center;
      max-width: 280px;
      margin: auto; }

#inner-banner {
  margin: 0; }
  #inner-banner .slide {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }

#breadcrumb {
  background: #fadf8f;
  padding: 15px; }
  #breadcrumb .crumbs {
    color: #193d7e;
    margin-bottom: 20px;
    display: none; }
    @media (min-width: 768px) {
      #breadcrumb .crumbs {
        display: block; } }
  #breadcrumb h2 {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    display: block;
    margin: 0;
    text-transform: uppercase;
    color: #b36d00;
    line-height: 1;
    letter-spacing: 1px; }
  #breadcrumb .text {
    color: #004161; }
  #breadcrumb .crumb {
    color: #193d7e; }
    #breadcrumb .crumb + .crumb:before {
      content: '>';
      margin: 0 5px; }
    #breadcrumb .crumb:last-child {
      font-family: "opensans-bold"; }

/*overwrite select2*/
.select2-container {
  color: #7d7d7d;
  border-radius: 1px;
  border: 1px solid #193d7e;
  background: white;
  height: 34px; }
  .select2-container .select2-selection--single {
    border: 0;
    border-radius: 1px; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      line-height: 32px; }
    .select2-container .select2-selection--single[aria-expanded="true"] .select2-selection__arrow:after {
      content: '\f106'; }
  .select2-container .select2-selection__arrow {
    height: 32px;
    text-align: center; }
    .select2-container .select2-selection__arrow:after {
      content: '\f107';
      font-family: FontAwesome;
      font-size: 16px;
      font-style: normal;
      line-height: 32px;
      display: block;
      color: #193d7e; }
    .select2-container .select2-selection__arrow b {
      display: none; }

#content .content-top {
  padding: 20px 0;
  background-color: #fadf8f; }
  @media (max-width: 991px) {
    #content .content-top {
      text-align: center; } }
  #content .content-top .title-txt {
    white-space: nowrap;
    margin: 0;
    color: #2559b4; }
    @media (max-width: 991px) {
      #content .content-top .title-txt {
        margin-bottom: 15px; } }
  #content .content-top .or {
    margin: 0 30px;
    color: #193d7e; }
    @media (max-width: 1200px) {
      #content .content-top .or {
        margin: 0 5px; } }
  #content .content-top select {
    width: 100%;
    display: block; }
  #content .content-top .select2-container {
    width: 100% !important;
    display: block;
    margin: auto; }
    @media (max-width: 991px) {
      #content .content-top .select2-container {
        max-width: 276px; } }

@media (max-width: 991px) {
  #content .course-select {
    margin-bottom: 15px; } }

#content .page-content p {
  color: #242428;
  text-align: justify; }

#content .page-content img {
  max-width: 100%;
  display: block; }

#content .page-content .read-more {
  color: #004161;
  font-size: 13px;
  position: relative; }
  #content .page-content .read-more:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-color: #1ca7e0;
    top: 50%;
    left: 100%;
    margin-left: 5px;
    margin-top: -3px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 3px solid #1ca7e0;
    border-left-color: inherit; }

#content .page-content .left-content {
  min-height: 232px;
  background-color: #ffffff; }
  #content .page-content .left-content > *:last-child {
    margin-bottom: 30px; }

#content .page-content .right-content {
  margin-top: -82px;
  position: relative;
  background-color: #2c5fb9; }
  #content .page-content .right-content > *:last-child {
    margin-bottom: 30px; }
  @media (max-width: 767px) {
    #content .page-content .right-content {
      margin: 0; } }
  #content .page-content .right-content:after {
    content: '';
    width: 100%;
    height: 83px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #2c5fb9; }
    @media (max-width: 767px) {
      #content .page-content .right-content:after {
        position: relative;
        height: 41.5px; } }

#content .content-bottom {
  margin-top: 30px; }

#content .inner {
  margin-top: 15px; }

.news-mini {
  padding: 30px 0;
  background-color: #f0f0f0;
  transition: all 0.5s ease-in-out; }
  .news-mini .slides {
    transition: opacity 0.5s ease-in-out;
    margin: 0;
    padding: 15px 0;
    background-color: white; }
    .news-mini .slides .slick-track, .news-mini .slides .slick-list {
      position: relative;
      z-index: 100; }
    .news-mini .slides .slide {
      text-align: center;
      padding: 15px;
      position: relative;
      z-index: 1; }
    .news-mini .slides .desc {
      border-bottom: 1px solid #f0f0f0; }
    .news-mini .slides h3 {
      color: #2559b4;
      text-transform: uppercase;
      margin-bottom: 15px; }
    .news-mini .slides p {
      color: #362100;
      text-align: justify;
      margin-bottom: 15px; }
    .news-mini .slides img {
      width: 100%;
      margin-bottom: 15px; }
    .news-mini .slides a.more {
      color: #b36d00;
      transition: all 0.5s ease; }
      .news-mini .slides a.more:hover {
        color: #193d7e; }
      .news-mini .slides a.more:after {
        content: '\f0a9';
        font-family: FontAwesome;
        font-size: 14px;
        margin-left: 5px; }
  .news-mini #news-mini-arrow {
    position: relative;
    height: 100%; }
  .news-mini .slick-arrow {
    width: auto; }
    .news-mini .slick-arrow:before {
      content: '';
      color: #193d7e;
      z-index: 2;
      position: relative; }
    .news-mini .slick-arrow:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      background-color: white;
      width: 30px;
      height: 30px;
      margin-top: -15px; }
  .news-mini .slick-prev {
    left: -10px; }
    .news-mini .slick-prev:before {
      content: '\f104';
      font-family: FontAwesome;
      font-size: 14px; }
    .news-mini .slick-prev:after {
      left: -5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
  .news-mini .slick-next {
    right: -10px; }
    .news-mini .slick-next:before {
      content: '\f105';
      font-family: FontAwesome;
      font-size: 14px; }
    .news-mini .slick-next:after {
      right: -5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }

.course-mini {
  background-color: grey;
  background-position-y: 100%;
  padding: 30px 0; }
  .course-mini .course-mini-title {
    position: relative;
    background: #fadf8f;
    padding: 30px 20px;
    margin: 0 0 15px;
    text-align: center; }
    .course-mini .course-mini-title h2 {
      margin: 0;
      color: #193d7e;
      letter-spacing: 1px;
      text-transform: uppercase; }
  .course-mini #course-mini-arrows {
    position: relative; }
    .course-mini #course-mini-arrows button {
      top: 50%;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      width: 30px;
      height: 30px;
      margin-top: -12.5px; }
      .course-mini #course-mini-arrows button:before {
        color: #193d7e;
        font-family: FontAwesome;
        font-size: 16px; }
      .course-mini #course-mini-arrows button.slick-prev {
        left: 0;
        right: auto; }
        .course-mini #course-mini-arrows button.slick-prev:before {
          content: '\f104'; }
      .course-mini #course-mini-arrows button.slick-next {
        right: 0; }
        .course-mini #course-mini-arrows button.slick-next:before {
          content: '\f105'; }
  .course-mini .course-list {
    margin: 0 -5px; }
    .course-mini .course-list .slide {
      margin: 0 5px;
      text-align: center;
      background-color: white; }
      .course-mini .course-list .slide .head {
        background-color: #adc9fb;
        padding: 30px 15px;
        margin: -15px -15px 15px; }
      .course-mini .course-list .slide .desc {
        padding: 15px 15px 0;
        position: relative;
        height: 100%; }
      .course-mini .course-list .slide .course-list-text {
        margin: 0 -15px;
        padding: 0 10px 65px;
        position: relative; }
      .course-mini .course-list .slide img {
        margin: 0 auto 10px;
        max-width: 50px; }
      .course-mini .course-list .slide h4 {
        color: #193d7e;
        text-transform: uppercase;
        margin: 0 auto;
        max-width: 100px;
        line-height: 1.2; }
      .course-mini .course-list .slide p {
        color: #242428; }
      .course-mini .course-list .slide a.more {
        position: absolute;
        bottom: 15px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0); }
        @media (max-width: 767px) {
          .course-mini .course-list .slide a.more {
            width: auto; } }

.course-content .course-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative; }
  .course-content .course-banner:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .course-content .course-banner h4 {
    padding: 60px 30px;
    color: white;
    line-height: 1.4;
    position: relative;
    margin: 0; }

.course-content .course-details {
  background-color: #f0f0f0; }
  .course-content .course-details .detail {
    padding: 30px;
    text-transform: uppercase; }
    .course-content .course-details .detail + .detail {
      margin-top: -30px; }
    .course-content .course-details .detail .label {
      color: #193d7e; }
    .course-content .course-details .detail .info {
      float: right;
      color: #193d7e; }
      body[dir="rtl"] .course-content .course-details .detail .info {
        float: left; }

.course-content .course-btns {
  padding: 30px;
  border-top: 1px solid white; }
  .course-content .course-btns .btn {
    white-space: nowrap;
    width: 100%;
    text-align: center;
    padding: 0 5px; }
    @media (max-width: 767px) {
      .course-content .course-btns .btn {
        margin-bottom: 15px; } }

.course-content .course-desc {
  padding: 30px 0; }

.course-content .course-page-cont {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #c8c8c8; }
  .course-content .course-page-cont .nav {
    background-color: #2c5fb9;
    list-style: none;
    padding: 0; }
    @media (max-width: 991px) {
      .course-content .course-page-cont .nav {
        display: none; } }
    .course-content .course-page-cont .nav li + li {
      border-top: 1px solid #2559b4; }
    .course-content .course-page-cont .nav li.active a, .course-content .course-page-cont .nav li:hover a {
      color: #f4bd00;
      background-color: transparent; }
    .course-content .course-page-cont .nav li a {
      color: white;
      text-transform: uppercase;
      display: block;
      text-align: center;
      line-height: 1.4;
      padding: 30px;
      transition: all 0.5s ease; }
      @media (max-width: 991px) {
        .course-content .course-page-cont .nav li a {
          padding: 30px 20px; } }
  .course-content .course-page-cont .tab-content {
    padding: 15px; }
    @media (max-width: 991px) {
      .course-content .course-page-cont .tab-content {
        padding: 0; } }
    @media (max-width: 991px) {
      .course-content .course-page-cont .tab-content .tab-pane {
        padding: 15px; } }
    @media (max-width: 991px) {
      .course-content .course-page-cont .tab-content .tab-pane:last-child {
        margin-bottom: -15px; } }
  .course-content .course-page-cont .tab-mobile {
    display: none;
    background-color: #3677ea;
    color: white;
    text-transform: uppercase;
    padding: 10px 15px;
    margin: 1px 0 0; }
    .course-content .course-page-cont .tab-mobile:first-child {
      margin-top: 0; }
    @media (max-width: 991px) {
      .course-content .course-page-cont .tab-mobile {
        display: block; } }

.subpage {
  margin: 30px auto 0; }
  .subpage .nav {
    margin-left: -15px; }
    @media (max-width: 991px) {
      .subpage .nav {
        display: none; } }
    .subpage .nav li + li {
      margin-top: 10px; }
    .subpage .nav li.active a, .subpage .nav li:hover a, .subpage .nav li:active a {
      color: #f4bd00; }
    .subpage .nav li.active a:after {
      border-left-color: #3677ea;
      left: 100%; }
    .subpage .nav li a {
      background-color: #3677ea;
      color: white;
      text-align: right;
      line-height: 1;
      position: relative;
      transition: all 0.5s ease; }
      .subpage .nav li a:after {
        content: " ";
        left: 96%;
        top: 50%;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: solid transparent;
        border-width: 10px;
        margin-top: -10px;
        transition: all 0.5s ease; }
  .subpage .tab-content {
    overflow: auto; }
    @media (max-width: 991px) {
      .subpage .tab-content {
        height: auto !important;
        overflow: visible; } }
    .subpage .tab-content .tab-mobile {
      display: none;
      background-color: #3677ea;
      color: white;
      text-transform: uppercase;
      padding: 10px 15px;
      margin: 1px -15px 0; }
      @media (max-width: 991px) {
        .subpage .tab-content .tab-mobile {
          display: block; } }
    .subpage .tab-content .tab-pane {
      padding: 8px 0; }
      .subpage .tab-content .tab-pane h3 {
        color: #2559b4; }

.student-testimonial {
  padding: 30px 0 50px;
  margin-top: 30px;
  background-color: grey;
  background-size: 200%;
  background-position-y: 100%; }
  @media (max-width: 991px) {
    .student-testimonial {
      background-size: cover; } }
  .student-testimonial div.title {
    color: white; }
    .student-testimonial div.title:before, .student-testimonial div.title:after {
      border-color: transparent !important; }
    .student-testimonial div.title h2 {
      background: transparent !important;
      color: white !important; }
  .student-testimonial .featured h3 {
    color: #193d7e;
    text-transform: uppercase; }
  .student-testimonial .featured p {
    color: #362100; }
  .student-testimonial .featured .desc {
    text-align: justify;
    background-color: #fadf8f;
    padding: 30px;
    margin: 0 -15px;
    height: 100%; }
  .student-testimonial .student-slider {
    list-style: none;
    padding: 0;
    margin: 5px -2.5px 0; }
    .student-testimonial .student-slider li {
      margin: 0 2.5px;
      background-color: white; }
      .student-testimonial .student-slider li > div {
        height: 100%; }
    .student-testimonial .student-slider .img {
      background-size: 90%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
      padding-top: 50%; }
    .student-testimonial .student-slider .desc {
      padding: 15px 10px 45px;
      position: relative;
      background-color: white;
      height: 100%; }
      @media (max-width: 991px) {
        .student-testimonial .student-slider .desc {
          height: auto; } }
      .student-testimonial .student-slider .desc h3 {
        color: #193d7e; }
      .student-testimonial .student-slider .desc p {
        color: #362100; }
      .student-testimonial .student-slider .desc a.more {
        position: absolute;
        bottom: 10px;
        right: 10px; }
        @media (max-width: 767px) {
          .student-testimonial .student-slider .desc a.more {
            width: auto;
            margin: auto;
            right: 50%;
            -webkit-transform: translate(50%, 0);
            transform: translate(50%, 0); } }
    .student-testimonial .student-slider button {
      top: 50%;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      width: 30px;
      height: 30px;
      margin: 0; }
      .student-testimonial .student-slider button:before {
        color: white;
        font-family: FontAwesome;
        font-size: 22px; }
      .student-testimonial .student-slider button.slick-prev {
        left: -30px;
        right: auto; }
        .student-testimonial .student-slider button.slick-prev:before {
          content: '\f104'; }
      .student-testimonial .student-slider button.slick-next {
        right: -30px; }
        .student-testimonial .student-slider button.slick-next:before {
          content: '\f105'; }

.testimonial-page .photo {
  max-width: 380px !important;
  width: 100%;
  float: left;
  margin: 0 15px 15px 0; }
  body[dir="rtl"] .testimonial-page .photo {
    float: right;
    margin: 0 0 15px 15px; }

.home-map .home-map-details {
  background-color: #193d7e;
  padding: 15px 0; }
  .home-map .home-map-details .title-cont {
    text-align: right;
    color: #f4bd00;
    text-transform: uppercase;
    padding: 0 30px 0 0; }
    @media (max-width: 767px) {
      .home-map .home-map-details .title-cont {
        text-align: center;
        margin-bottom: 15px; } }
    .home-map .home-map-details .title-cont h2 {
      margin: 0; }
  .home-map .home-map-details h4, .home-map .home-map-details .contact-label {
    color: white;
    margin: 0; }
  .home-map .home-map-details h4 {
    margin-bottom: 5px; }
  .home-map .home-map-details p {
    color: #4e81db;
    margin: 0;
    line-height: 1.4; }
  .home-map .home-map-details .address {
    text-transform: uppercase; }

.home-map .col-sm-height {
  padding: 0 30px; }
  .home-map .col-sm-height + .col-sm-height {
    border-left: 1px solid rgba(0, 0, 0, 0.4); }
    body[dir="rtl"] .home-map .col-sm-height + .col-sm-height {
      border-left: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.4); }
    @media (max-width: 767px) {
      .home-map .col-sm-height + .col-sm-height {
        border: 0;
        margin-top: 15px; } }

.uno-mfp-popup {
  margin: auto;
  padding: 15px; }

#newsletter {
  padding: 50px 0;
  width: 100%;
  margin: 0;
  text-align: center;
  background-image: url("../../img/sheffield/subscribe-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  #newsletter h3 {
    color: #2c5fb9;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    text-align: right; }
    body[dir="rtl"] #newsletter h3 {
      text-align: left; }
    @media (max-width: 767px) {
      #newsletter h3 {
        text-align: center;
        margin-bottom: 15px; } }
  #newsletter .link {
    color: white;
    font-size: 13px;
    display: none;
    position: relative; }
    #newsletter .link:before {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-color: #1ca7e0;
      top: 50%;
      left: 100%;
      margin-left: 5px;
      margin-top: -3px;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 3px solid #1ca7e0;
      border-left-color: inherit; }
  #newsletter .input-cont {
    position: relative;
    max-width: 600px; }
    @media (max-width: 1200px) {
      #newsletter .input-cont {
        max-width: 450px; } }
    @media (max-width: 767px) {
      #newsletter .input-cont {
        margin: auto; } }
    #newsletter .input-cont input {
      border: 1px solid #193d7e;
      color: #7d7d7d;
      background-color: #f0f0f0;
      width: 100%;
      padding: 5px 130px 5px 5px;
      border-radius: 1px;
      height: 44px; }
      body[dir="rtl"] #newsletter .input-cont input {
        padding: 5px 5px 5px 130px; }
    #newsletter .input-cont button.btn {
      position: absolute;
      right: 2px;
      top: 2px;
      height: 40px; }
      body[dir="rtl"] #newsletter .input-cont button.btn {
        right: auto;
        left: 2px; }
  #newsletter .form-group {
    display: none; }
    #newsletter .form-group .form-control {
      width: 100%;
      height: 34px;
      font-weight: normal;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif; }
    #newsletter .form-group .form-control-feedback {
      width: auto;
      height: auto;
      font-size: 14px;
      margin: 10px; }

.a-banner {
  margin-top: 30px; }

#get-quote {
  margin-top: 30px; }

#event-calendar {
  margin-top: 30px; }

#home-video-cont {
  padding: 0;
  height: 279px; }
  #home-video-cont a {
    display: block;
    width: 100%; }
  #home-video-cont .vid {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

#popUpsContainer {
  display: none; }

.white-popup.popup {
  padding: 10px; }
  .white-popup.popup .container {
    background-color: white;
    padding: 15px; }

footer #footer-content {
  padding: 30px 0 60px;
  background-color: #26272b; }
  footer #footer-content h4 {
    color: white;
    text-transform: uppercase;
    margin-bottom: 15px; }
  footer #footer-content .bottom-footer {
    padding: 15px 0; }
    footer #footer-content .bottom-footer .links {
      color: white; }
    footer #footer-content .bottom-footer .site-map {
      color: white; }

.footer-top {
  padding-bottom: 30px; }
  .footer-top p {
    font-size: 10px;
    color: white;
    margin: 0;
    line-height: 1.4; }
  .footer-top .footer-logo img {
    margin-bottom: 10px; }
  .footer-top .hotline {
    margin-top: 15px; }
    .footer-top .hotline i {
      color: #1ca7e0;
      vertical-align: middle; }
    .footer-top .hotline .phone-number {
      display: inline-block;
      color: white;
      vertical-align: middle;
      margin: 0; }
      .footer-top .hotline .phone-number > a {
        color: white; }
  @media (max-width: 767px) {
    .footer-top .quick-nav + .quick-nav {
      margin-top: 30px; } }
  .footer-top .quick-nav:hover h4 {
    color: #b36d00; }
  .footer-top .quick-nav:hover a {
    color: white; }
  .footer-top .quick-nav ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  .footer-top .quick-nav li + li a {
    margin-top: 5px; }
  .footer-top .quick-nav a {
    display: block;
    color: #7d7d7d;
    transition: all 0.5s ease; }
    .footer-top .quick-nav a:hover {
      color: #f4bd00 !important; }
  .footer-top .address {
    margin-bottom: 10px; }

.footer-bottom {
  color: #b36d00;
  padding: 15px 0;
  background-color: #242428; }
  .footer-bottom p {
    margin: 0; }
  .footer-bottom a {
    color: #b36d00; }

.back-top {
  color: #b36d00;
  text-transform: uppercase;
  text-align: right;
  cursor: pointer; }
  body[dir="rtl"] .back-top {
    text-align: left; }
  .back-top span {
    margin-right: 10px; }
    body[dir="rtl"] .back-top span {
      margin-left: 10px; }

#social-icon {
  margin-bottom: 5px; }
  @media (max-width: 991px) {
    #social-icon {
      margin-top: 30px; } }
  #social-icon ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: inline-block; }
    #social-icon ul li {
      font-size: 16px;
      display: inline-block; }
      #social-icon ul li a {
        opacity: 0.7;
        color: #F3F3F3;
        padding: 0 5px; }
        #social-icon ul li a:hover {
          opacity: 1; }
        #social-icon ul li a img {
          max-height: 31px;
          vertical-align: bottom; }

/*inner page*/
#inner {
  background-color: white; }

.parent-label {
  padding: 30px 15px 0;
  text-align: center; }
  .parent-label:after {
    content: '';
    border-top: 1px solid #2559b4;
    display: block;
    margin-top: 15px; }
  .parent-label .parent-icon {
    margin: 0 auto 15px; }
  .parent-label .parent-name {
    color: white;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 1px; }

.side-menu {
  text-align: left; }
  .side-menu ul {
    padding: 0;
    list-style: none; }
    .side-menu ul li {
      padding: 0;
      position: relative; }
      .side-menu ul li a {
        padding: 15px 25px;
        text-transform: uppercase;
        color: #f4bd00;
        display: block;
        letter-spacing: 1px;
        transition: all 0.5s ease; }
        body[dir="rtl"] .side-menu ul li a {
          text-align: right; }
        .side-menu ul li a.arrow {
          position: absolute;
          color: #193d7e;
          top: 1px;
          right: 10px;
          padding: 12px 10px;
          border: 0;
          transition: all 0.5s ease; }
          body[dir="rtl"] .side-menu ul li a.arrow {
            right: auto;
            left: 10px; }
          .side-menu ul li a.arrow:hover {
            color: white; }
          .side-menu ul li a.arrow[aria-expanded="true"]:after {
            content: '\f0d8'; }
          .side-menu ul li a.arrow:after {
            content: '\f0d7';
            font-family: FontAwesome;
            font-size: 14px; }
      .side-menu ul li ul li:hover > a {
        color: #193d7e;
        background-color: #3e73d2; }
      .side-menu ul li ul li a {
        padding: 10px 25px;
        text-transform: initial;
        color: white;
        background-color: #2559b4;
        border-bottom: 1px solid #3e73d2; }
      .side-menu ul li ul li ul li a {
        padding: 10px 25px; }

.side-guide {
  text-align: left; }
  .side-guide ul {
    padding: 0;
    list-style: none; }
    .side-guide ul li {
      position: relative;
      padding: 15px 25px;
      text-transform: uppercase;
      color: #f4bd00;
      display: block;
      letter-spacing: 1px;
      transition: all 0.5s ease; }
      body[dir="rtl"] .side-guide ul li {
        text-align: right; }
      .side-guide ul li.arrow {
        position: absolute;
        color: #193d7e;
        top: 1px;
        right: 10px;
        padding: 12px 10px;
        border: 0;
        transition: all 0.5s ease; }
        body[dir="rtl"] .side-guide ul li.arrow {
          right: auto;
          left: 10px; }
        .side-guide ul li.arrow:hover {
          color: white; }
        .side-guide ul li.arrow[aria-expanded="true"]:after {
          content: '\f0d8'; }
        .side-guide ul li.arrow:after {
          content: '\f0d7';
          font-family: FontAwesome;
          font-size: 14px; }

#search {
  padding: 15px 0;
  max-width: 300px;
  cursor: pointer; }
  #search form {
    margin: 0;
    position: relative; }
  #search input {
    background-color: #242428;
    border-radius: 1px;
    border: 0;
    height: 34px;
    color: #7d7d7d;
    width: 300px; }

#searchBtn {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  background-color: transparent;
  border: 0;
  color: #2c5fb9;
  font-size: 16px;
  display: inline-block; }
  body[dir="rtl"] #searchBtn {
    right: auto;
    left: 5px; }

.editor-mode [data-block-editable] {
  outline: dashed #7f7f7f 1px; }

@media (min-width: 768px) {
  #divChangeViews {
    display: none; } }

[data-mh] {
  transition: height 0.3s; }

.text-editor-content a:not(.btn), .static-page-content a:not(.btn) {
  text-decoration: underline; }

.enrollment-form.form-horizontal .radio, .enrollment-form.form-horizontal .checkbox, .enrollment-form.form-horizontal .radio-inline, .enrollment-form.form-horizontal .checkbox-inline {
  padding-top: 4px; }

.enrollment-form table .form-group {
  margin-bottom: 0; }

.enrollment-form .panel {
  border-radius: 0; }

.enrollment-form .input-group-addon {
  border-radius: 0; }

.enrollment-form .inline-input {
  display: inline-block;
  width: auto; }

.enrollment-form label {
  font-size: 13px; }

.enrollment-form input, .enrollment-form select, .enrollment-form textarea {
  width: 100%;
  border-radius: 0; }
  .enrollment-form input:active, .enrollment-form select:active, .enrollment-form textarea:active {
    border-color: #193d7e; }
  .enrollment-form input:focus, .enrollment-form select:focus, .enrollment-form textarea:focus {
    border-color: #193d7e; }

.enrollment-form textarea {
  resize: none; }

.enrollment-form .radio-inline .iradio_square-blue {
  margin-right: 5px; }

.enrollment-form .icheckbox_square-blue {
  margin-right: 5px; }

@media (max-width: 991px) {
  .enrollment-form .discovery-method > div {
    width: 100%; } }

.enrollment-form .discovery-method label {
  width: 25%;
  margin: 0; }
  @media (max-width: 1200px) {
    .enrollment-form .discovery-method label {
      width: 33.33%; } }
  @media (max-width: 767px) {
    .enrollment-form .discovery-method label {
      width: 50%; } }

.enrollment-form .select2-container {
  border-color: #ccc;
  width: 100% !important; }
  .enrollment-form .select2-container:active {
    border-color: #193d7e; }

.agent-application-form input, .agent-application-form select, .agent-application-form textarea {
  width: 100%;
  border-radius: 0; }
  .agent-application-form input:active, .agent-application-form select:active, .agent-application-form textarea:active {
    border-color: #193d7e; }
  .agent-application-form input:focus, .agent-application-form select:focus, .agent-application-form textarea:focus {
    border-color: #193d7e; }

.agent-application-form .input-group-addon {
  border-radius: 0; }

.agent-application-form .radio-inline .iradio_square-blue {
  margin-right: 5px; }

.agent-application-form .icheckbox_square-blue {
  margin-right: 5px; }

.agent-application-form .table {
  margin: 0; }

.agent-application-form .panel-body .panel .panel-title {
  font-size: 13px; }

@media (max-width: 991px) {
  .agent-application-form .edu-sector > div {
    width: 100%; } }

.agent-application-form .edu-sector label {
  width: 25%;
  margin: 0;
  float: left; }
  @media (max-width: 1200px) {
    .agent-application-form .edu-sector label {
      width: 50%; } }
  @media (max-width: 767px) {
    .agent-application-form .edu-sector label {
      width: 50%; } }

.alert-dismissable .close {
  right: auto; }

.bootbox-body {
  font-size: 14px; }

/*# sourceMappingURL=app.css.map */
